.card-img-overlay {
    opacity: 0.0;
}
  
.card-img-overlay:hover {
  opacity: 0.95;
  transition: .3s ease-in-out;
  border-color: grey !important;
  border-width: .1rem !important;
  border-style: ridge;
}

.card-title {
  color: white !important;
  opacity: 0.75;
  pointer-events: none;
}

.card {
  --bs-card-border-color: dark !important;
  --bs-card-border-width: .2rem !important;
}

.nav-underline .nav-link {
  color: white;
  font-size: 18px !important;
}

.nav-underline .nav-link:hover, .nav-tabs .nav-link:focus{
  color: white;
}

.nav-underline .nav-link.active{
  color: white;
}

/* .nav-underline .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: rgba(var(--bs-altlight-rgb)) !important ;
} */

