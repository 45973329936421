.tv-wrapper {
  max-width: 960px;
  max-height:540px;
  width:90vw;
  height:50.85vw;
  margin:0 auto;
}
  
iframe {
  width:100%;
  height:100%;
}

/* .row-cols-md-2 {
  flex-grow: 4;
} */

.card-body {
  white-space: pre-line;
}

.h4{
  color: white;
}

.btn-close {
  background: white var(--bs-btn-close-bg) center/1em auto no-repeat;
  --bs-btn-close-opacity: 0.75;
  --bs-btn-close-hover-opacity: 0.95;
}