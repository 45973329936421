.tv-wrapper {
    max-width:100% !important;
    max-height: 785px;
}

iframe {
    border-color: black !important;
    border-width: .2rem !important;
    border-style: solid;

 }