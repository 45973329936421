.input-group-text {
    background-color: var(--bs-card-color) ;
}

.input-group-text {
    color: rgb(var(--bs-altlight-rgb)) !important; 
}

#formGridName.form-control{
    box-shadow:none;
}
#formGridName.form-control:focus{
    border: var(--bs-warning);
    border-width: .1rem;
    border-style: solid;
}
#formGridName .form-control:not(active){
    color:white;
 }

 #formGridName .form-control:focus{
    color:black;
 }

#formGridEmail.form-control{
    box-shadow:none;
}
#formGridEmail.form-control:focus{
    border: var(--bs-warning);
    border-width: .1rem;
    border-style: solid;
}

#formGridSubject.form-control{
    box-shadow:none;
}
#formGridSubject.form-control:focus{
    border: var(--bs-warning);
    border-width: .1rem;
    border-style: solid;
}

textarea.form-control{
    box-shadow:none !important;
}
textarea.form-control:focus{
    border: var(--bs-warning);
    border-width: .1rem;
    border-style: solid;

}