.nav-link {
    font-size: 18px !important;
    font-weight: 200 !important;    
}

.nav-link:hover {
    font-weight: 400 !important;
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-left: 26px !important;
    color: lightgrey;
}

.navbar {
    border-bottom: .1rem ridge grey;
}

.navbar-toggler{
    padding: 0 !important;
    font-size: "2rem" !important;
    border: 1rem !important;
}

/* .navbar-nav{
    --bs-nav-link-color: black !important;
} */

/* ### LOGO ANIMATION */

.logo_animated_1_simplified_svg__cls-1{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0s;
}

.logo_animated_1_simplified_svg__cls-2{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0.1s;
}

.logo_animated_1_simplified_svg__cls-3{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0.2s;
}

.logo_animated_1_simplified_svg__cls-4{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0.3s;
}

.logo_animated_1_simplified_svg__cls-5{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0.4s;
}

.logo_animated_1_simplified_svg__cls-6{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0.5s;
}

.logo_animated_1_simplified_svg__cls-7{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0.6s;
}

.logo_animated_1_simplified_svg__cls-8{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0.7s;
}

.logo_animated_1_simplified_svg__cls-9{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0.8s;
}

.logo_animated_1_simplified_svg__cls-10{
    animation: logo-anim 5s step-end forwards;
    animation-delay: 0.9s;
}

@keyframes logo-anim {
    0% {
        fill: transparent;       
    }
    50% {
        fill: white;
    }
    100% {
        fill: white;
    }   
}